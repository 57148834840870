@import "variables.less";

// Custom Stuff
// --------------------------------------------------
// Nav logo and custom nav styles (in place of bootstrap's .navbar-brand

.nav-logo {
    float: left;
    padding: 10px;
    height: 70px;
}

.nav-main-unit {
    float: left;
    margin: 15px 20px 6px 20px;
    cursor: pointer;
    
    .nav-title {
        font-size: 20px;
        color: @brand-primary;
        margin: 0;
    }
    
    .nav-caption {
        font-size: 13px;
        color: @isc-commons-navy-light;
        margin-top: -6px;
    }
}

// Text/Fonts + Media queries
// --------------------------------------------------

.text-lead-sm {
  @media (max-width: @screen-sm-max) {
    font-size: 16px;
  }
}// used in feature hero 

.text-h1-sm {
  @media (max-width: @screen-sm-max) {
    font-size: 36px;
  }
}// used in feature hero 

.semibold {
    font-family: @font-semibold;
}

.label_bold {
    font-family: lato_bold;
    font-size: 18px;
}

.larger_font {
    font-size: 18px;
}

.large_font {
    font-size: @font-size-large;
}


// Coloring
// --------------------------------------------------

.white {
    color: #ffffff;   
}

.dark-blue {
    color: @brand-dark-blue;
}

.purple {
    color: @purple;
}

.red {
    color: @red_dark;
}

.red_bg {
    background-color: @red_dark;
}

.gold {
    color: @gold;
}

// Spacing
// -------------------------

.padding0 {
padding: 0px;
} // ex used to remove padding from .container-fluid

.padding-tbL {
    padding: 40px 0;
} // used to space out items so they look like different chunks of content

.margin-topS { margin-top: 10px; }

.margin-topM { @media (min-width: @screen-sm) {
margin-top: 20px; }
@media (max-width: @screen-sm) {
margin-top: 10px; }
} // often used to space sections

.margin-topL { @media (min-width: @screen-sm) {
margin-top: 40px; }
@media (max-width: @screen-sm) {
margin-top: 20px; }
} // often used to space sections

.margin-topXL { @media (min-width: @screen-sm) {
margin-top: 80px; }
@media (max-width: @screen-sm) {
margin-top: 40px; }
} // often used to space sections

.margin-lr0 {
    margin-left: 0px;
    margin-right: 0px;
} // hack to fix extra space on the right

.margin-lrM {
    margin: 0 20px;
} // used to space out items


// IDs 
// --------------------------------------------------------------

#footer {
 //margin-top: 40px;
 padding: 30px;
 //background: @brand-primary;
 background: #ffffff;
 width: 100%;
 font-size: 14px;
 border-top: 1px solid @blue-light-grey;
 color: @brand-dark-blue;
}

#footer p {
 font-size: 14px;
}

#footer a {
 color: @brand-primary;
}

#footer li {
    display: inline;
    padding-right: 20px;
}

// links to faq

#faq-links a {
    margin: 0 20px;
}


// Extra Utility Classes

.display-inline {
    display: inline;
}

// RH WEB LOGIN PAGE

.loginbox select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input {
		display: inline-block;
		height: 40px;
		font-size: 14px;
		border-radius: 4px;
		border: 1px solid #e3e3e3;
		width: 100%;
		padding: 10px;
		margin-bottom: 16px;
	}

.loginbox {
		border: 1px solid #f3f3f3;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		padding: 30px 0 0 0;
		-webkit-box-shadow: 0 2px 1px #d2d2d2;
		-moz-box-shadow: 0 2px 1px #d2d2d2;
		box-shadow: 0 2px 1px #d2d2d2;
		background: #ffffff;
    }

.loginbox h3 {
		margin-bottom: 30px;
		font-family: @font-semibold;
		text-align: center;
	}

.loginbox label {
		margin-bottom: 0;
	}

.loginfooter span {
		cursor: pointer; 
		color: #1097ff;
	}

.loginbox ul {
		padding: 10px 0;
	}

.loginbox li {
		list-style: none;
    }

.success_message {
		background: #F0EFFF;
		color: #6A5FFF;
		padding: 8px;
		border-radius: 4px;
		text-align: center;
		margin-bottom: 15px;
		border: 1px solid #D2CFFF;
	}


// SURVEYS - these appear on the home page, which is logged_in.html 

.surveybox {
    border-top: 1px solid #d9d9d9;
	border-bottom: 1px solid #d9d9d9;
	border-left: 1px solid #d9d9d9;
	border-right: 1px solid #d9d9d9;
	border-radius: 10px;
	padding: 30px 30px 0px 30px;
	-webkit-box-shadow: 0 2px 1px #cccccc;
	-moz-box-shadow: 0 2px 1px #cccccc;
	box-shadow: 0 2px 1px #cccccc;
	background: #ffffff;
	
	h2 {
	    margin: 0 0 0 20px;
	}
	
	h5 {
	    margin: 10px 0 32px 20px;
	}
}

.survey-hog {
    margin-top: 12px;
}

.surveyrow {
    border-top: 1px solid @blue-light-grey;
    padding: 20px 0;
}

.survey-text {
    margin: 0 0 0 20px;
}

.rewarded-surveys-section {
    border-top: 1px solid @blue-light-grey;
    background-color: #F5FCFF;
    padding-bottom: 20px;
    margin-top: -5px;
    
    h6 {
        font-family: @font-semibold;
        margin: 20px 0 12px 24px;
    }
    
    .title {
        font-size: @font-size-base*0.75; // should be 12 if the base is 16
        margin: 0 0 6px 24px;
    }
    
    .completed-date {
        font-size: @font-size-base*0.75;
        margin: 0 20px 6px 0;
    }
}

// --------- name this later ------------ //

.light-blue-container {
    border-bottom: 1px solid @blue-light-grey;
    border-top: 1px solid @blue-light-grey;
    padding: 56px;
    background-color:#F5FCFF;
}

.code {
    font-size: 3rem;
    color: @purple;
} // for personal url with your referral code in the invite page


.message-box {
    text-align: center;
    color: #ffffff;
    margin: 0 auto 20px auto;
    background-color: @isc-commons-navy-dark;
    width: 84%;
    padding: 12px 64px;
    font-size: 13px;
    .message-text {
        line-height: 2;
    }
} // usually used in a block element like p


// MY REFERRAL CODE and EMAIL VERIFIED pages

.downloadrow {
    margin-top: 50px;
}

.downloadrow a {
    display: inline-block;
    margin-right: 12px;
}

.landing-code-header h1 {color: #ffffff;}
.landing-code-header h4 {color: #ffffff; text-align: left; margin-bottom: 24px;}

.landing-code-aboutRH p {text-align: center; color: @brand-dark-blue;}
.landing-code-aboutRH h3 {text-align: center; color: @brand-dark-blue;}
.landing-code-aboutRH h4 {text-align: center; color: @brand-dark-blue;}

.landing-code-header {
    background-image: url(../../images/landing/landing_header_bg.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: @rh-dark-blue;
    min-height: 560px;
    -webkit-box-shadow: 0 2px 1px #cccccc;
	-moz-box-shadow: 0 2px 1px #cccccc;
	box-shadow: 0 2px 1px #cccccc;
  }
  

// ARROW - USED IF NO SURVEYS AVAILABLE, THE HOG SAYS NO SURVEYS  
// arrow box - from cssarrowplease.com
  
.arrow_box {
	position: relative;
	background: @rh-dark-blue;
	color: #ffffff;
}
.arrow_box:after, .arrow_box:before {
	left: 100%;
	top: 60%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow_box:after {
	border-left-color: @rh-dark-blue;
	border-width: 30px;
	margin-top: -30px;
}
.arrow_box:before {
	border-left-color: @rh-dark-blue;
	border-width: 30px;
	margin-top: -30px;
}

.no-survey-text-box {
    height: 160px;
    margin-left: 150px;
    padding: 20px !important;
}

@media (max-width: 1214px) {
    .no-survey-text-box h4 {  font-size: 21px; }
}

.banner h1{
    margin:0;
    text-align: center;
}

.banner p {
    text-align: center;
    font-size: 21px;
}